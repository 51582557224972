.o-picture {
  display: block;
  padding: 5rem 0;
  background-color: #f0f0f0;

  @include bp-medium {
    padding: 5rem;
  }
}

.o-picture img {
  display: block;
  margin: auto;
  max-width: 100%;
}
