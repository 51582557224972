.o-highlight {
  position: relative;
  letter-spacing: 0;
  background-color: $color-medium-grey;

  &[data-highlight]:before {
    content: attr(data-highlight);
  }
}

.o-highlight--primary {
  @extend .o-highlight;
  background-color: $color-primary-v2;

  .theme-purple & {
    background-color: $color-purple-v2;
  }
}

.o-highlight--secondary {
  @extend .o-highlight;
  background-color: $color-secondary;
}
