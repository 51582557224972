/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  min-height: 100%;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 0.625rem;
  line-height: 1.5;
  background: $color-bg;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;

  &.has-modal-active { // stylelint-disable-line selector-no-qualifying-type
    overflow-y: hidden;
  }
}

body {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;

  .has-modal-active & {
    overflow-y: hidden;
  }
}
