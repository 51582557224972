.c-branches__container {
  text-align: center;

  @include bp-large {
    text-align: left;
  }
}

.c-branches__image {
  display: block;
  max-width: 17rem;
  margin: 3rem auto;

  @include bp-large {
    position: absolute;
    right: -5rem;
    top: -6rem;
    max-width: 20rem;
    margin: 0;
  }

  @include bp-full {
    position: absolute;
    top: -7rem;
    max-width: 24rem;
  }
}
