.c-symbol--abstract-1 {
  display: none;

  @include bp-large {
    display: block;
    position: absolute;
    width: 15rem;
    right: 5%;
    bottom: 0;
    transform: translateY(60%) rotate(-55deg);
  }
}

.c-symbol--swan {
  display: none;
  position: absolute;

  @include bp-large {
    display: block;
    top: 50%;
    right: -5rem;
    width: 16rem;
    transform: translateY(-50%);
  }

  @include bp-full {
    width: 25rem;
    right: -15rem;
    transform: translateY(-50%);
  }
}

.c-symbol--abstract-3 {
  display: block;
  margin: 0 auto;
  width: 10rem;

  @include bp-large {
    position: absolute;
    top: 6.5rem;
    right: 10%;
    width: 14rem;
  }

  @include bp-full {
    width: 18.5rem;
  }
}

.c-symbol--abstract-4 {
  display: block;
  margin: 0 auto 5rem;
  width: 20rem;

  @include bp-large {
    position: absolute;
    top: 18rem;
    right: 4rem;
  }

  @include bp-full {
    top: 24rem;
    right: 7rem;
    width: 31.7rem;
  }
}
