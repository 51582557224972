/* stylelint-disable value-list-comma-space-after */
@font-face {
  font-family: calibreweb;
  font-display: swap;
  src:
    url('../assets/fonts/CalibreWeb-Light.woff2') format('woff2'),
    url('../assets/fonts/CalibreWeb-Light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src:
    url('../assets/fonts/CalibreWeb-Regular.woff2') format('woff2'),
    url('../assets/fonts/CalibreWeb-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src:
    url('../assets/fonts/CalibreWeb-RegularItalic.woff2') format('woff2'),
    url('../assets/fonts/CalibreWeb-RegularItalic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src:
    url('../assets/fonts/CalibreWeb-Medium.woff2') format('woff2'),
    url('../assets/fonts/CalibreWeb-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src:
    url('../assets/fonts/CalibreWeb-MediumItalic.woff2') format('woff2'),
    url('../assets/fonts/CalibreWeb-MediumItalic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src:
    url('../assets/fonts/CalibreWeb-Semibold.woff2') format('woff2'),
    url('../assets/fonts/CalibreWeb-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src:
    url('../assets/fonts/CalibreWeb-Bold.woff2') format('woff2'),
    url('../assets/fonts/CalibreWeb-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}
