.u-veryshort {
  max-width: 28rem;
}

.u-veryshort--large {
  @include bp-large {
    max-width: 28rem;
  }
}

.u-veryshort--full {
  @include bp-large {
    max-width: 37rem;
  }
}

.u-short--large {
  @include bp-large {
    max-width: 45rem;
  }
}

.u-short--full {
  @include bp-full {
    max-width: 56rem;
  }
}
