/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding-left: $wrapper-padding;
  padding-right: $wrapper-padding;

  @include bp($wrapper-width-mid) {
    max-width: $wrapper-width-mid;
    padding-left: calc(#{$wrapper-padding} - (50vw - #{$wrapper-width-mid/2}));
    padding-right: calc(#{$wrapper-padding} - (50vw - #{$wrapper-width-mid/2}));
  }

  @include bp($wrapper-width-mid + $wrapper-padding*2) {
    padding-left: 0;
    padding-right: 0;
  }

  @include bp-large {
    max-width: $wrapper-width-mid - 160px;
  }

  @include bp-full {
    max-width: $wrapper-width-big - 320px;
  }
}

.o-wrapper--static {
  position: static;
}
