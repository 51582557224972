.c-interlude {
  position: relative;
  width: calc(50% + #{$wrapper-width-mid/2});
  height: 50vw;
  max-height: 31.5rem;
  background: url('../assets/images/bg-01.jpg') center right no-repeat;
  background-size: cover;

  @include bp-full {
    width: calc(50% + #{$wrapper-width-big/2});
    max-height: 42rem;
  }
}

.c-interlude__shape {
  position: absolute;
  left: 10%;
  right: 0;
  top: 35%;
  height: 65%;
  max-height: 15rem;
  background: rgba($color-primary, 0.5);

  @include bp-large {
    top: 12.8rem;
    left: 34%;
    right: 0;
  }

  @include bp-full {
    top: 21rem;
    left: auto;
    right: 10%;
    width: 66.7rem;
  }
}
