/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.o-btn {
  display: inline-block;
  vertical-align: middle;
  padding: 0.9rem 2rem 1.1rem;
  border: 1px solid $color-medium-grey;
  border-radius: 23px;
  margin: 3rem 0;
  font-family: inherit;
  font-size: 1.9rem;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  transition: all $global-transition-time;
  cursor: pointer;

  @include bp-large {
    padding: 1rem 3rem;
    // border-width: 2px;
    margin: 3rem 0;
    font-size: 2.1rem;
  }

  @include bp-full {
    margin: 4rem 0;
  }

  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-medium-grey;
  }
}

.o-btn--center {
  margin-left: auto;
  margin-right: auto;
}

.o-btn--left-on-large {
  @include bp-large {
    margin-left: 0;
  }
}

.o-btn--collapse {
  display: table; // center and collapse margin with surrounding elements
}

.o-btn--primary {
  color: $color-primary;
  border-color: $color-primary;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-primary;
  }
}

.theme-purple .o-btn--primary {
  font-weight: 300;
  color: $color-purple;
  border-color: $color-purple;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-purple;
  }
}

.o-btn--secondary {
  color: $color-secondary;
  border-color: $color-secondary;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-secondary;
  }
}

.o-btn--small {
  line-height: 1;
  padding: 0.6rem 2rem 0.7rem;
  border-width: 1px;
  font-size: 2.16rem;
}

.o-btn--outline {
  color: $color-white;
  border-color: $color-white;

  &:hover,
  &:focus {
    color: $color-primary;
    background-color: $color-white;
  }
}

.theme-purple .o-btn--outline {
  &:hover,
  &:focus {
    color: $color-purple;
  }
}
