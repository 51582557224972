.c-images {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0;

  @include bp-small {
    justify-content: flex-start;
  }
}

.c-images__image {
  max-width: 7.5rem;

  @include bp-full {
    max-width: 10rem;
  }

  & + & {
    margin-left: 2rem;

    @include bp-small {
      margin-left: 4rem;
    }
  }
}
