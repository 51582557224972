.u-center {
  text-align: center;
}

.u-center-children > * {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.u-flexcenter {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.u-left {
  text-align: left;
}
