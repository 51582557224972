.c-figures {
  text-align: center;

  @include bp-large {
    display: flex;
    justify-content: center;
  }
}

.c-figures--align-left {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.c-figures__item {
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include bp-large {
    margin: 3rem 3rem 0;
  }

  @include bp-full {
    margin: 3rem 3rem 0;
  }
}

.c-figures--align-left .c-figures__item {
  margin: 3.5rem 0;

  &:nth-child(n+2) {
    margin-left: 3rem;

    @include bp-large {
      margin-left: 8rem;
    }

    @include bp-large {
      margin-left: 11rem;
    }
  }
}

.c-figures__image {
  display: block;
  max-width: 13rem;
  max-height: 9rem;
  margin: auto;
}

.c-figures--align-left .c-figures__image {
  max-width: 7.4rem;
  max-height: none;

  @include bp-full {
    max-width: 10rem;
  }
}

.c-figures__caption {
  margin-top: 2rem;
  max-width: 15rem;
  font-size: 1.6rem;
  line-height: 1.2;
}
