.u-no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.u-vpadding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.u-vpadding--medium {
  @include bp-medium {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.u-vpadding-2 {
  padding-top: 5rem;
  padding-bottom: 8rem;
}

.u-vpadding-connector {
  padding-top: 3rem;

  @include bp-large {
    padding-top: 1rem;
  }

  @include bp-full {
    padding-top: 1px;
  }
}

.u-vpadding--vimeo {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.u-vpadding--big {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @include bp-large {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @include bp-full {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

.u-vpadding--bigger {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 9rem;

  @include bp-large {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  @include bp-full {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}

.u-vpadding--biggest {
  padding-top: 12rem;
  padding-bottom: 12rem;

  @include bp-large {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  @include bp-full {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
}
