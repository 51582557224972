.c-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40rem;
  max-width: $wrapper-width-mid;
  max-height: 100vh;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: auto;
  margin-right: auto;
  background-color: $color-very-light-grey;
  background-position: center center;
  background-size: cover;

  @include bp-large {
    height: 50.6rem;
  }

  @include bp-full {
    height: 67.5rem;
    max-width: $wrapper-width-big;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10rem;
    height: 1.2rem;
    background-color: $color-primary-v2;
    transform: translateY(100%);

    .theme-purple & {
      background-color: $color-purple;
    }

    @include bp-large {
      width: 14.7rem;
      height: 1.7rem;
    }

    @include bp-full {
      width: 19.7rem;
      height: 2.3rem;
    }
  }
}

.c-hero__container {
  position: relative;
  width: 100%;

  @include bp-large {
    max-width: $wrapper-width-mid - 160px;
  }

  @include bp-full {
    max-width: $wrapper-width-big - 320px;
  }
}

.c-hero__h3 {
  padding-left: $wrapper-padding;
  padding-right: $wrapper-padding;
  color: $color-white;

  @include bp-large {
    color: $color-primary;
    padding-left: 0;
    padding-right: 0;
  }

  @include bp-full {
    max-width: 73rem;
    font-size: 3.4rem;
  }
}
