.c-section-about__flex {
  @include bp-large {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.c-section-about__graphic {
  max-width: 10.8rem;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: -3rem;
  transform: translateX(50%) translateY(50%);

  @include bp-large {
    left: -8rem;
    top: 0;
    right: auto;
    bottom: auto;
    flex-shrink: 0;
    width: 9.89rem;
    height: 13.3rem;
    transform: none;
  }

  @include bp-full {
    left: -12rem;
  }
}

.c-section-about__image {
  max-width: 100%;
  max-height: 100%;
}

.c-section-about__text {
  @include bp-large {
    max-width: 58.5rem;
  }

  @include bp-full {
    max-width: 78rem;
  }

  *:first-child {
    margin-top: 0;
  }
}
