/* ==========================================================================
   #STYLEGUIDE
   ========================================================================== */

$style-guide-color-medium: #666;
$style-guide-color-light: #999;
$style-guide-color-border: #ccc;

.c-style-guide__intro {
  // border: 1px solid $style-guide-color-border;
  // border-bottom-width: 2px;
  // border-right-width: 2px;
  // margin: 2em 0 1em 0;
  // padding: 2em;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.c-style-guide__title {
  // color: $style-guide-color-medium;
  // font-size: 1.25rem;
  // font-weight: normal;
  // margin: 0 0 0.25em 0;
  // text-transform: uppercase;
}

.c-style-guide__desc {
  color: $style-guide-color-medium;
  margin: 0;
}

.c-style-guide__section {
  @include clearfix();
  padding: 1rem 0;
}

.c-style-guide__section-title {
  border-bottom: 1px solid $style-guide-color-border;
  clear: both;
  color: $style-guide-color-medium;
  font-size: 1.25rem;
  font-weight: normal;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  width: 50%;
}

.c-style-guide__section-subtitle {
  border-left: 6px solid $style-guide-color-border;
  clear: both;
  color: $style-guide-color-light;
  font-size: 1.125rem;
  font-weight: normal;
  margin: 2em 0;
  padding-left: 1em;
  text-transform: uppercase;
}
