/* stylelint-disable value-list-comma-space-after, indentation */

.c-hamburger {
  padding: 1.5rem 2rem;

  @include bp-large {
    display: none;
  }
}

.c-hamburger__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.8rem;
  height: 2rem;
  transform: translateZ(1px);
  cursor: pointer;
}

.c-hamburger__bar {
  display: block;
  width: 100%;
  height: 2px;
  transform-origin: center center;
  transition: transform $global-transition-time/2 $global-transition-time/2,
              opacity 0s $global-transition-time/2;
}

.c-hamburger__inner {
  display: block;
  width: 100%;
  height: 100%;
  background: white;
  transition: transform $global-transition-time/2;
}

.has-menu-opened {
  .c-hamburger__bar {
    transition: transform $global-transition-time/2,
                opacity 0s $global-transition-time/2;

    &:first-child {
      transform: translateY(0.9rem);
    }

    &:last-child {
      transform: translateY(-0.9rem);
    }

    &:not(:first-child):not(:last-child) {
      opacity: 0;
    }
  }

  .c-hamburger__inner {
    transition: transform $global-transition-time/2 $global-transition-time/2;
  }

  :first-child > .c-hamburger__inner {
    transform: rotate(45deg);
  }

  :last-child > .c-hamburger__inner {
    transform: rotate(-45deg);
  }
}

/* stylelint-enable value-list-comma-space-after, indentation */
