.c-brands {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;

  @include bp-small {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: none;
    margin: 0;
  }
}

.c-brands__logo {
  margin: 2rem;
  align-self: center;

  @include bp-small {
    margin: 3rem;
  }

  @include bp-medium {
    margin: 4rem;
  }

  @include bp-large {
    margin: 5rem;
  }

  @include bp-full {
    margin: 6rem;
  }

  &--nature {
    width: 10rem;
    opacity: 0.4;
  }

  &--nature-energy {
    width: 10rem;
    opacity: 0.45;
  }

  &--caltech {
    width: 10rem;
    opacity: 0.35;
  }
}
