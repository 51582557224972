.c-menu {
  width: 100%;
  margin: 0;

  .has-menu-opened & {
    transform: translateY(0);
  }
}

.c-menu--header {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 5rem;
  padding: 1.35rem 0;
  background-color: $color-primary-v3;
  transform: translateY(-100%);
  transition: transform $global-transition-time;

  @include bp-large {
    display: flex;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    padding: 0;
    margin-right: 2rem;
    background-color: transparent;
    transform: none;
  }

  .theme-purple & {
    background-color: $color-purple;

    @include bp-large {
      background-color: transparent;
    }
  }
}

.c-menu--footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.35rem 0;

  @include bp-large {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
}

.c-menu__item {
  margin: 0;
  list-style-type: none;
  flex-shrink: 1;

  @include bp-large {
    display: block;
  }
}

.c-menu__link {
  display: inline-block;
  color: $color-white;
  font-size: 1.8rem;
  line-height: 1.22;
  font-weight: normal;
  text-decoration: none;

  @include bp-full {
    padding: 1rem 2rem;
  }
}

.c-menu--header .c-menu__link {
  padding: 0.85rem $wrapper-padding 0.85rem #{$wrapper-padding + 15px};
  opacity: 0;
  transition: all $global-transition-time;

  .has-menu-opened & {
    opacity: 1;
  }

  @include bp-large {
    opacity: 1;
  }
}

.c-header--inverted .c-menu__link {
  @include bp-large {
    color: $color-primary;
  }
}

.has-menu-sticky .c-header--inverted .c-menu__link,
.always-menu-sticky .c-header--inverted .c-menu__link {
  color: $color-white;
}

.c-menu--footer .c-menu__link {
  font-size: 1.8rem;
  padding: 0.5rem 3rem;
  letter-spacing: 0.57px;

  @include bp-large {
    padding: 0.5rem 1rem;
  }
}

@for $i from 1 through 10 {
  .has-menu-opened .c-menu__item:nth-last-child(#{$i}) .c-menu__link {
    transition-delay: #{$i * 0.05}s;
  }
}

.c-menu__shortname {
  display: none;

  @include bp-large {
    display: block;
  }
}

.c-menu__fullname {
  display: block;

  @include bp-large {
    display: none;
  }
}

.c-menu--footer .c-menu__shortname {
  display: block;
}

.c-menu--footer .c-menu__fullname {
  display: none;
}
