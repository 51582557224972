/* ==========================================================================
   #NORMALIZE
   ========================================================================== */

@import 'normalize-scss/sass/normalize/import-now';

[width] {
  width: auto;
}

[height] {
  height: auto;
}
