.c-collaborators {
  align-items: center;
  margin-bottom: 3rem;

  @include bp-medium {
    display: flex;
    justify-content: space-between;
  }
}

.c-collaborators__link {
  display: block;
  max-width: 15rem;
  padding: 1.5rem;
  margin: 2rem auto;

  @include bp-medium {
    width: 20%;
    max-width: 15rem;
    padding-left: 0;
    padding-right: 0;
    margin: 2rem 0;
  }
}

.c-collaborators__logo {
  width: 100%;
}
