.c-closing-x {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  padding: 2rem;
}

.c-closing-x__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.8rem;
  height: 2rem;
  transform: translateZ(1px);
  cursor: pointer;
}

.c-closing-x__bar {
  display: block;
  width: 100%;
  height: 2px;
  transform-origin: center center;

  &:first-child {
    transform: translateY(0.9rem);
  }

  &:last-child {
    transform: translateY(-0.9rem);
  }
}

.c-closing-x__inner {
  display: block;
  width: 100%;
  height: 100%;
  background: white;

  :first-child > & {
    transform: rotate(45deg);
  }

  :last-child > & {
    transform: rotate(-45deg);
  }
}
