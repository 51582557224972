.c-tiles {
  position: relative;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;

  @include bp-large {
    max-width: 65rem;
  }

  @include bp-full {
    max-width: 85rem;
  }
}

.c-tiles__title {
  text-align: center;
  margin: 3rem auto;
  max-width: 24rem;
  line-height: 1.25;


  @include bp-large {
    max-width: none;
    height: 2.6rem;
    color: $color-purple;
    border: 1px solid $color-purple;
    border-bottom: 0;
    margin: 0 0 2rem 0;
  }
}

.c-tiles__title-text {
  @include bp-large {
    display: inline-block;
    background-color: $color-very-light-grey;
    padding-left: 2rem;
    padding-right: 2rem;
    transform: translateY(-50%);
  }
}

.c-tiles__legend {
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;

  @include bp-large {
    display: none;
  }

  @include bp-full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 5.2rem;
    bottom: 0;
    width: 20rem;
    margin-top: 0;
    transform: translateX(-100%);
  }
}

.c-tiles__legend-item {
  align-items: center;
  max-width: 15rem;
  color: $color-purple;
  font-size: 1.4rem;
  line-height: 1.2;
  text-align: center;

  @include bp-full {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 50%;
  }
}

.c-tiles__legend-item:first-child {
  @include bp-full {
    margin-bottom: 1.8rem;
  }
}

.c-tiles__legend-item:last-child {
  @include bp-full {
    margin-top: 1.8rem;
    padding: 2.5rem 0;
  }
}

.c-tiles__legend-text {
  @include bp-full {
    display: block;
    margin-top: 1rem;
    font-size: 1.6rem;
  }
}

.c-tiles__legend-symbol {
  display: none;

  @include bp-full {
    display: block;
    width: 9rem;
  }
}

.c-tiles__content {
  font-size: 0;

  @include bp-large {
    display: flex;
  }
}

.c-tiles__group {
  display: flex;

  @include bp-large {
    flex-direction: column;
    width: 20%;
    margin-top: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }

  & + & {
    margin-top: 2rem;

    @include bp-large {
      margin-top: 0;
    }
  }
}

.c-tiles__tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 2rem;
  border: 1px solid $color-purple;

  line-height: 1;

  @include bp-large {
    width: 100%;
    height: 16rem;
    border-top-color: transparent;
    border-right-width: 0;
    padding: 2.5rem 1rem;


    &:first-child {
      padding-top: 0;
      margin-bottom: 1.3rem;
    }

    &:last-child {
      margin-top: 1.3rem;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 10%;
        width: 1px;
        background: rgba($color-white, 0.2);
      }
    }
  }

  @include bp-full {
    height: 19rem;

    &:first-child {
      margin-bottom: 1.8rem;
    }

    &:last-child {
      margin-top: 1.8rem;
    }
  }
}

:first-child > .c-tiles__tile:last-child:after {
  @include bp-large {
    display: none;
  }
}

:last-child > .c-tiles__tile {
  @include bp-large {
    border-right-width: 1px;
  }
}

.c-tiles__tile--fill {
  background-color: $color-purple;
}

.c-tiles__symbol {
  width: 7.7rem;
  height: 7.7rem;
  flex-shrink: 0;
  max-width: none;

  @include bp-full {
    width: 9rem;
    height: 9rem;
  }
}

.c-tiles__label {
  margin-top: 1rem;
  color: $color-purple;
  text-align: center;
  font-size: 1.2rem;

  @include bp-full {
    font-size: 1.6rem;
  }
}

.c-tiles__tile--fill .c-tiles__label {
  color: $color-white;
}

.c-tiles__heading {
  margin: 3rem 0;
  color: $color-purple-v2;
  font-size: 1.9rem;
  text-align: center;

  @include bp-large {
    position: absolute;
    width: 100%;
    height: 2.6rem;
    top: calc(50% + 2.3rem);
    left: 50%;
    border-left: 1px solid $color-purple;
    border-right: 1px solid $color-purple;
    margin: 0;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.6rem;
    text-align: center;
    white-space: nowrap;
    background-color: $color-white;
    transform: translateX(-50%)  translateY(-50%);
    overflow: hidden;
  }

  @include bp-full {
    height: 3.6rem;
    line-height: 3.6rem;
  }
}
