/* ==========================================================================
   #TABLES
   ========================================================================== */

/**
 * 1. Ensure tables fill up as much space as possible.
 */

table {
  width: 100%; /* [1] */
  text-align: left;
  font-size: 0.85rem;
  line-height: 1;

  @include bp-small {
    font-size: 1.3rem;
  }

  @include bp-full {
    font-size: 1.7rem;
  }
}

th, td {
  padding: 0.7rem 1.5rem;
  border: solid $color-very-light-grey;
  border-width: 0 0 0.1rem 0.05rem;

  &:first-child {
    border-left-width: 0;
  }

  @include bp-small {
    padding: 1.4rem 1.9rem;
    border-width: 0 0 0.2rem 0.7rem;

    &:first-child {
      border-left-width: 0;
    }
  }

  @include bp-full {
    padding: 1.7rem 2.6rem;
  }
}

th {
  font-weight: 600;
  color: $color-primary;
  background: $color-very-light-grey;
  border-bottom-color: white;

  .theme-purple & {
    color: $color-purple;
  }
}

td {
  background: white;
}

thead th {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: $color-primary-v2;
  border-bottom-width: 0;

  @include bp-small {
    font-size: 1.5rem;
    border-bottom-width: 0;
  }

  @include bp-full {
    font-size: 2rem;
  }

  .theme-purple & {
    color: white;
    background: $color-purple-v2;
  }
}
