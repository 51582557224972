.c-vimeo {
  display: flex;
  justify-content: center;
}

.c-vimeo__iframe {
  width: 100%;
  max-width: 100%;
  height: 58vw;
  max-height: 36rem;
  margin: auto;
}
