/* ==========================================================================
   #HEADINGS
   ========================================================================== */

h1 {
  width: 100%;
  padding-left: $wrapper-padding;
  padding-right: $wrapper-padding;
  margin: 0;
  color: $color-white;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.72px;

  [data-highlight] {
    font-size: 0;
    padding: 1.4rem 0 0;
  }

  [data-highlight]:before {
    font-size: 3rem;
  }

  @include bp-large {
    margin-top: 10rem;
    max-width: $wrapper-width-mid - 160px;
    padding-left: 0;
    padding-right: 0;
    font-size: 4.5rem;

    [data-highlight] {
      font-size: 0;
      padding: 2.1rem 0 0;
    }

    [data-highlight]:before {
      font-size: 4.5rem;
    }
  }

  @include bp-full {
    margin-top: 15rem;
    max-width: $wrapper-width-big - 320px;
    font-size: 6rem;

    [data-highlight] {
      font-size: 0;
      padding: 2.8rem 0 0;
    }

    [data-highlight]:before {
      font-size: 6rem;
    }
  }
}

h2 {
  margin: 3rem 0;
  color: $color-primary;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.15px;

  [data-highlight] {
    font-size: 0;
    padding: 1.2rem 0 0;
  }

  [data-highlight]:before {
    font-size: 2.4rem;
  }

  @include bp-medium {
    font-size: 3.2rem;
    letter-spacing: 0.2px;

    [data-highlight] {
      font-size: 0;
      padding: 1.5rem 0 0;
    }

    [data-highlight]:before {
      font-size: 3.2rem;
    }
  }

  @include bp-large {
    margin: 4rem 0 3rem;
  }

  @include bp-full {
    font-size: 4rem;
    letter-spacing: 0.25px;

    [data-highlight] {
      font-size: 0;
      padding: 1.9rem 0 0;
    }

    [data-highlight]:before {
      font-size: 4rem;
    }
  }
}

h3 {
  margin: 2rem 0;
  color: $color-primary;
  font-size: 1.8rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.24px;

  @include bp-large {
    margin: 3rem 0 2rem;
  }

  @include bp-full {
    margin: 4rem 0 2rem;
    font-size: 2.4rem;
    letter-spacing: 0.32px;
  }
}

h3.has-even-vmargin { // stylelint-disable-line selector-no-qualifying-type
  margin-top: 2rem;
  margin-bottom: 2rem;

  @include bp-large {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @include bp-full {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

h4 {
  margin: 0;
  color: $color-primary;
  font-size: 1.9rem;
  font-weight: 500;
}

/* stylelint-disable selector-no-qualifying-type */

h2,
h3,
h4 {
  .theme-purple & {
    color: $color-purple;
  }
}

/* stylelint-enable selector-no-qualifying-type */
