.c-simple {
  display: flex;
  flex-direction: column;

  @include bp-large {
    flex-direction: row;
  }
}

.c-simple__image {
  align-self: center;
  width: 20rem;
  margin-top: 2rem;

  @include bp-large {
    align-self: flex-start;
    margin-top: 0;
    margin-right: 6.6rem;
  }

  @include bp-full {
    width: 27rem;
    flex-shrink: 0;
  }
}

.c-simple__heading {
  @include bp-large {
    margin-top: 0;
  }
}
