/* ==========================================================================
   #COLOR
   ========================================================================== */

.u-color {
  color: $color-text;
}

.u-color--primary {
  color: $color-primary;

  .theme-purple & {
    color: $color-purple-v2;
  }
}

.u-color--secondary {
  color: $color-secondary;
}

.u-fill {
  background: $color-fill;
}
