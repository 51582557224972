.o-connector {
  position: relative;
  z-index: 1;
  padding-bottom: 8rem;

  @include bp-large {
    padding-bottom: 8rem;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    display: block;
  }

  &::before { // line
    left: 50%;
    bottom: 0;
    width: 1px;
    height: 14rem;
    background: $color-purple;
    transform: translateY(50%);
  }

  &::after { // circle
    left: 50%;
    bottom: 7rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid $color-purple;
    margin: 0;
    border-radius: 50%;
    transform: translateX(-0.95rem);
  }
}

.o-connector.o-bg--left {
  &::before,
  &::after {
    @include bp-large {
      right: $wrapper-width-mid/2;
    }

    @include bp-full {
      right: $wrapper-width-big/2;
    }
  }
}

.o-connector.o-bg--right {
  &::before,
  &::after {
    @include bp-large {
      left: $wrapper-width-mid/2;
    }

    @include bp-full {
      left: $wrapper-width-big/2;
    }
  }
}

@include bp-large {
  left: $wrapper-width-mid/2;
}

@include bp-full {
  left: $wrapper-width-big/2;
}

.o-connector + * {
  padding-top: 5rem;

  @include bp-large {
    padding-top: 9rem;
  }
}

.o-connector--shifted-up {
  &::before {
    transform: translateY(5rem) ;
  }

  &::after {
    transform: translateY(-2rem) translateX(-0.95rem);
  }
}

.o-connector--flipped {
  &::before {
    bottom: 1rem;
  }

  &::after {
    bottom: -8rem;
  }
}

.o-connector--line {
  &::before {
    height: 16rem;
    @include bp-large {
      left: calc(#{$wrapper-width-mid/2});
    }

    @include bp-full {
      left: calc(#{$wrapper-width-big/2});
    }
  }

  &::after {
    display: none;
  }
}

.o-connector--secondary {
  &::before {
    background: $color-secondary !important;
  }

  &::after {
    border-color: $color-secondary !important;
  }
}
