/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 50%;
  width: 100%;
  height: 5rem;
  padding: 0;
  max-width: $wrapper-width-mid;
  transform: translateX(-50%);

  @include bp-large {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.8rem;
    margin: auto;
    background-color: transparent;
    transition: background-color $global-transition-time;
  }

  @include bp-full {
    height: 7.7rem;
    max-width: $wrapper-width-big;
  }

  .has-menu-sticky &,
  .always-menu-sticky & {
    @include bp-large {
      background-color: $color-primary-v2;
    }
  }

  .has-menu-sticky .theme-purple &,
  .always-menu-sticky .theme-purple & {
    @include bp-large {
      background-color: $color-purple-v2;
    }
  }
}

.c-header__inner {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $color-primary-v2;

  .theme-purple & {
    background-color: $color-purple-v2;
  }

  @include bp-large {
    width: auto;
    background-color: transparent;
  }
}

.c-header__homelink {
  display: block;
  position: relative;
  z-index: 1;
  padding: 1.5rem 2rem;

  @include bp-large {
    padding: 1.85rem 3rem;
    background: $color-primary-v2;

    .theme-purple & {
      background-color: $color-purple-v2;
    }
  }

  @include bp-full {
    padding: 2.8rem 3rem;
  }
}

.c-header--diagnostics .c-header__homelink {
  padding: 1rem 2rem;

  @include bp-large {
    padding: 1.45rem 3rem;
  }

  @include bp-full {
    padding: 1.9rem 3rem;
  }
}

.c-header__logo {
  display: block;
  position: relative;
  z-index: 1;
  max-height: 2.1rem;

  .c-header--diagnostics & {
    max-height: 2.9rem;

    @include bp-full {
      max-height: 3.9rem;
    }
  }
}
