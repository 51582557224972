.o-figure {
  display: block;
  margin: 5rem auto;

  &[width],
  &[height] {
    max-width: 24rem;
  }

  &.is-small {
    max-width: 16rem;
  }

  @include bp-medium {
    margin: 0 auto;
  }
}
