section {
  margin-top: 3rem;
  margin-bottom: 3rem;

  @include bp-large {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  @include bp-full {
    margin-top: 14.5rem;
    margin-bottom: 14.5rem;
  }
}

.theme-purple section {
  margin-top: 6rem;
  margin-bottom: 6rem;

  @include bp-large {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  @include bp-full {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
}
