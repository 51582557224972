.c-exploration {
  margin: 0 0 6rem 1rem;

  @include bp-medium {
    margin-left: 0;
    margin-top: 4rem;
    display: flex;
  }

  @include bp-full {
    margin-top: 6rem;
  }
}

.c-exploration__item {
  margin-bottom: 4rem;
  padding: 0 2rem;
  border-left: 0.1rem solid $color-primary;

  .theme-purple & {
    border-left-color: $color-purple;
  }

  @include bp-medium {
    width: 33.33%;
  }

  @include bp-full {
    margin-left: 2rem;
  }
}
