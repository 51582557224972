.c-article__banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &--regular {
    height: 23.3rem;
    background-image: url('../assets/images/bg-verticals-materials.jpg');
    background-position-x: 75%;

    @include bp-large {
      height: 35rem;
    }

    @include bp-full {
      height: 46.5rem;
    }
  }

  &--tall {
    height: 33.8rem;
    background-image: url('../assets/images/bg-verticals-energy.jpg');
    background-position-y: bottom;

    @include bp-full {
      height: 45rem;
    }
  }
}
