.c-comparison-table {
  @include bp-large {
    display: flex;
  }
}

.c-comparison-table__inner {
  margin-bottom: 0;

  @include bp-large {
    flex: 1 0 auto;
    width: 33%;
  }

  & + & {
    margin-top: 5rem;

    @include bp-large {
      margin-top: 0;
    }
  }

  td,
  th {
    height: 3.5rem;

    @include bp-large {
      height: 5.5rem;
    }

    @include bp-full {
      height: 7rem;
    }

    &:first-child {
      width: 12rem;

      @include bp-small {
        width: 24rem;
      }

      @include bp-large {
        display: none;
        width: 50%;
      }
    }
  }

  &:first-child {
    @include bp-large {
      flex: 2 0 auto;
      width: 66%;

      td:first-child,
      th:first-child {
        display: table-cell;
      }
    }
  }
}

.c-comparison-table__images {
  td {
    background: transparent;
    border-color: transparent;
  }
}

.c-comparison-table__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13rem;

  img {
    display: block;
    max-height: 100%;
    max-width: 100%;
  }
}

.c-comparison-table__sota {
  background: $color-medium-grey;

  .theme-purple & {
    background: $color-medium-grey;
  }
}
