/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  font-size: 0.875rem;
  margin: 0;
  padding-top: 0;
  background-color: $color-primary-v2;

  @include bp-large {
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 0;
  }

  .theme-purple & {
    background-color: $color-purple-v2;
  }
}

.c-footer__spacer {
  flex-grow: 1;
}

.c-footer__homelink {
  position: relative;
  z-index: 1;
  padding: 1.5rem 3rem;
  margin-bottom: 2rem;

  @include bp-large {
    padding: 1rem 3rem 1.15rem;
    margin-bottom: 0;
    background: $color-primary-v2;

    .theme-purple & {
      background: $color-purple-v2;
    }
  }
}

.c-footer__logo {
  display: block;
  width: 11rem;
  max-width: none;
}

.c-footer__btn {
  position: absolute;
  right: $wrapper-padding;
  top: $wrapper-padding;
  margin: 0;

  @include bp-large {
    position: relative;
    top: auto;
    right: auto;
    margin-right: 3rem;
    align-self: center;
  }
}

.c-footer__links {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: $wrapper-padding;
  list-style-type: none;
  background: $color-primary;

  &:after {
    content: '';
    flex-grow: 1;
  }

  @include bp-large {
    display: none;
  }

  .theme-purple & {
    background: $color-purple-v3;
  }
}

.c-footer__item {
  margin: 0;
  color: $color-white;
}

.c-footer__itemlink {
  padding: 1rem;
  color: #b8ccf8;
  font-size: 1.4rem;
  text-decoration: none;
}

.c-footer__links-desktop {
  flex: 0 0 auto;
  margin: 0;
  list-style: none;
  margin-right: 3rem;
  align-items: center;

  display: none;

  @include bp-large {
    display: flex;
  }
}

.c-footer__item-desktop {
  margin: 0;
}

.c-footer__itemlink-desktop {
  padding: 1rem;
  color: #5678c0;
  font-size: 1.5rem;
  text-decoration: none;
}
