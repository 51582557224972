.u-no-vmargin,
.theme-purple .u-no-vmargin {
  margin-top: 0;
  margin-bottom: 0;
}

.u-margin {
  @include bp-large {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}

.u-demargin {
  @include bp-large {
    margin-left: -8rem;
    margin-right: -8rem;
  }
}

// Top-bottom margins

.u-vmargin {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.u-vmargin--big {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
