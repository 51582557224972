.o-bg {
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;
  background-color: $color-very-light-grey;

  &--left {
    @include bp-large {
      padding-left: calc(50vw - #{$wrapper-width-mid/2});
      margin-right: calc(50vw - #{$wrapper-width-mid/2});
    }

    @include bp-full {
      padding-left: calc(50vw - #{$wrapper-width-big/2});
      margin-right: calc(50vw - #{$wrapper-width-big/2});
    }
  }

  &--right {
    @include bp-large {
      padding-right: calc(50vw - #{$wrapper-width-mid/2});
      margin-left: calc(50vw - #{$wrapper-width-mid/2});
    }

    @include bp-full {
      padding-right: calc(50vw - #{$wrapper-width-big/2});
      margin-left: calc(50vw - #{$wrapper-width-big/2});
    }
  }

  &--primary {
    background-color: $color-primary;

    .theme-purple & {
      background-color: $color-purple-v2;
    }
  }
}

.o-bg--periodic {
  min-height: 13.3rem;

  @include bp-large {
    min-height: 8.4rem;
  }

  @include bp-full {
    min-height: 35.2rem;
    background: url('../assets/images/bg-periodic.jpg') center center no-repeat;
    background-size: cover;
  }
}

.o-bg--bubbles {
  min-height: 32.4rem;
  background: url('../assets/images/diagnostics/bg-bubbles.jpg') center center no-repeat;
  background-size: cover;
}

.o-bg--pet {
  min-height: 27.7rem;
  background: url('../assets/images/diagnostics/bg-pet.jpg') center center no-repeat;
  background-size: cover;

  @include bp-large {
    min-height: 32.4rem;
  }

  @include bp-full {
    min-height: 42.8rem;
  }
}

.o-bg--about {
  min-height: 12rem;
  margin-left: auto;
  margin-right: auto;

  @include bp-large {
    max-width: $wrapper-width-mid;
  }

  @include bp-full {
    max-width: $wrapper-width-big;
  }
}
