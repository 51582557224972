p {
  margin: 2rem 0;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.21px;

  @include bp-full {
    font-size: 1.9rem;
  }
}
