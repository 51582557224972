.c-404 {
  position: relative;
  max-width: $wrapper-width-mid;
  padding: 1px 2rem;
  margin: auto !important;
  background-color: $color-very-light-grey;

  @include bp-full {
    max-width: $wrapper-width-big;
    min-height: 60rem;
    padding-left: ($wrapper-width-big - $wrapper-width-mid) / 2;
    padding-right: ($wrapper-width-big - $wrapper-width-mid) / 2;
  }
}

.c-404__heading {
  margin-top: 5rem;
  padding-left: 0;
  padding-right: 0;

  @include bp-large {
    margin-top: 15rem;
  }
}

.c-404__subheading {
  font-size: 1.6rem;

  @include bp-large {
    font-size: 2.4rem;
    max-width: 55%;
  }
}
