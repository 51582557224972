.o-link {
  display: block;
  text-decoration: none;
}

.o-link__text {
  position: relative;
  display: inline-block;
  color: $color-primary;
  font-size: 1.8rem;
  text-decoration: none;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0.9rem;
    margin-top: 1rem;
    background: $color-secondary;
  }
}
