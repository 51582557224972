/* ==========================================================================
   #LIST-BARE
   ========================================================================== */

/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */

.o-list-bare {
  list-style: none;
  margin: 0;

  @include bp-large {
    margin: 0 0 0 8rem;
  }
}

.o-list-bare__item {
  line-height: 1.25;
  padding: 0.8rem 0;

  @include bp-large {
    letter-spacing: 0.25px;
  }

  @include bp-full {
    font-size: 1.9rem;
  }
}

.o-list-bare__link {
  text-decoration: none;

  span {
    display: block;
  }
}
