.c-periodic-table__flex {
  @include bp-large {
    display: flex;
  }
}

.c-periodic-table__container--image {
  @include bp-large {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 45%;
  }
}

.c-periodic-table__container--text {
  @include bp-large {
    display: flex;
    justify-content: flex-start;
    width: 55%;
  }
}

.c-periodic-table__image {
  display: block;
  margin: 0 auto;
  width: 21rem;
  height: 21rem;

  @include bp-large {
    width: 27rem;
    height: 27rem;
    margin-right: 7rem;
  }

  @include bp-full {
    width: 34rem;
    height: 30rem;
  }
}

.c-periodic-table__group {
  margin-top: 3rem;

  @include bp-large {
    display: flex;
    margin-top: 3rem;
  }

  @include bp-large {
    margin-top: 4rem;
  }
}

.c-periodic-table__heading2 {
  @include bp-large {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-periodic-table__heading3 {
  @include bp-large {
    text-align: center;
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }

  @include bp-full {
    max-width: 88.5rem;
  }
}

.c-periodic-table__heading4 {
  font-size: 1.9rem;
  margin-bottom: 1rem;

  @include bp-large {
    align-self: flex-start;
    min-width: 14rem;
    margin: 0 2rem 1rem 0;
    font-size: 1.6rem;
    text-align: right;
    line-height: 1;
  }

  @include bp-full {
    font-size: 1.8rem;
  }
}

.c-periodic-table__paragraph {
  margin: 0;
  font-size: 1.6rem;

  @include bp-large {
    max-width: 23.4rem;
    font-size: 1.6rem;
  }

  @include bp-full {
    max-width: 29rem;
    font-size: 1.8rem;
  }
}

.c-periodic-table--alt {
  @include bp-large {
    .c-periodic-table__image {
      max-width: 30rem;
    }

    .c-periodic-table__flex {
      justify-content: center;
    }

    .c-periodic-table__container {
      width: auto;
    }

    .c-periodic-table__paragraph {
      max-width: 29.3rem;
    }

    .c-periodic-table__heading4 {
      text-align: left;
    }

    .c-periodic-table__group {
      display: block;
    }
  }
}
