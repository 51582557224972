/* ==========================================================================
   #LISTS
   ========================================================================== */

li {
  margin-bottom: 0.5rem;

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}
