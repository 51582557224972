/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  color: $color-primary;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: italic;
  line-height: 1.25;

  @include bp-medium {
    max-width: 55rem;
    padding-left: 2.2rem;
    border-left: 1px solid $color-primary;
    font-size: 1.84rem;
    line-height: 1.3;
  }

  @include bp-full {
    max-width: 68rem;
    padding-left: 2.8rem;
    font-size: 2.3rem;
    line-height: 1.3;
  }

  p {
    font: inherit;

    &:first-of-type:before {
      content: open-quote;
    }

    &:last-of-type:after {
      content: close-quote;
    }
  }

  footer span {
    display: block;
  }
}
