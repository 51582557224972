.c-statistics__element {
  display: flex;
  padding: 2rem 0;
  align-items: center;
}

.c-statistics__val {
  position: relative;
  width: 5rem;
  color: $color-primary;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 5rem;
    height: 5rem;
    border: 1px solid $color-text;
    border-radius: 2.5rem;
    transform: translateX(-50%) translateY(-50%);
  }
}

.c-statistics__val--double:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 4.2rem;
  height: 4.2rem;
  border: 1px solid $color-text;
  border-radius: 2.1rem;
  transform: translateX(-50%) translateY(-50%);
}

.c-statistics__attr {
  margin-left: 2.5rem;
  color: $color-primary;
  font-size: 1.8rem;
  font-weight: 500;
  font-style: italic;
}
