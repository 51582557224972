.o-separator {
  display: block;
  margin: 0 2rem;
  height: 0.2rem;
  max-width: 82.5rem;
  background: $color-very-light-grey;
  border: 0;

  @include bp-large {
    margin: 0 auto;
    height: 0.4rem;
  }

  .o-bg & {
    background: white;
  }
}
